if (!window.perf) {
    window.perf = {
        events: [],
        startTime: performance.now(),
        levels: { DEBUG: 0, INFO: 1, SUCCESS: 2, ERROR: 3 },
        // Private properties with sessionStorage defaults
        _table: sessionStorage.getItem('perf_table') === null ? false : sessionStorage.getItem('perf_table') !== 'false',
        _time: sessionStorage.getItem('perf_time') === null ? true : sessionStorage.getItem('perf_time') !== 'false',
        _heap: sessionStorage.getItem('perf_heap') === null ? true : sessionStorage.getItem('perf_heap') !== 'false',
        _data: sessionStorage.getItem('perf_data') === null ? true : sessionStorage.getItem('perf_data') !== 'false',
        _name: sessionStorage.getItem('perf_name') === null ? true : sessionStorage.getItem('perf_name') !== 'false',
        _level: sessionStorage.getItem('perf_level') === null ? 1 : parseInt(sessionStorage.getItem('perf_level')),
        _enabled: sessionStorage.getItem('perf_enabled') === null ? false : sessionStorage.getItem('perf_enabled') !== 'false',
        _levelEnabled: {}, // Start empty
        reset() {
            this.table = false;
            this.time = true;
            this.heap = true;
            this.data = true;
            this.name = true;
            this.level = this.levels.INFO;
            this.enabled = true;
            this.levelEnabled = {
                DEBUG: true,
                INFO: true,
                SUCCESS: true,
                ERROR: true,
            };
            this.events = [];
            this.startTime = performance.now();
            console.log('Performance settings reset to defaults');
        },
        // Getters/setters for persistence
        get table() {
            return this._table;
        },
        set table(value) {
            this._table = value;
            sessionStorage.setItem('perf_table', value);
        },
        get time() {
            return this._time;
        },
        set time(value) {
            this._time = value;
            sessionStorage.setItem('perf_time', value);
        },
        get heap() {
            return this._heap;
        },
        set heap(value) {
            this._heap = value;
            sessionStorage.setItem('perf_heap', value);
        },
        get data() {
            return this._data;
        },
        set data(value) {
            this._data = value;
            sessionStorage.setItem('perf_data', value);
        },
        get name() {
            return this._name;
        },
        set name(value) {
            this._name = value;
            sessionStorage.setItem('perf_name', value);
        },
        get level() {
            return this._level;
        },
        set level(value) {
            this._level = value;
            sessionStorage.setItem('perf_level', value);
        },
        get enabled() {
            return this._enabled;
        },
        set enabled(value) {
            this._enabled = value;
            sessionStorage.setItem('perf_enabled', value);
        },
        get levelEnabled() {
            return this._levelEnabled;
        },
        set levelEnabled(value) {
            this._levelEnabled = value;
            Object.keys(value).forEach((level) => {
                sessionStorage.setItem(`perf_level_${level.toLowerCase()}`, value[level]);
            });
        },
        on() {
            this.enabled = true;
            console.log('Performance logging enabled');
        },
        off() {
            this.enabled = false;
            console.log('Performance logging disabled');
        },
        _addEvent: (name, type = 'Info', data = null) => {
            var _a, _b, _c;
            if (!window.perf.enabled)
                return;
            const typeLevel = window.perf.levels[type.toUpperCase()];
            if (!window.perf.levelEnabled[type.toUpperCase()])
                return;
            if (typeLevel < window.perf.level && !window.perf.levelEnabled[type.toUpperCase()])
                return;
            const now = performance.now();
            const last = window.perf.events[window.perf.events.length - 1];
            const delta = last ? now - last.timestamp : 0;
            const event = {
                name,
                type,
                timestamp: now,
                delta,
                data,
                heap: {
                    // @ts-ignore
                    used: (((_a = performance === null || performance === void 0 ? void 0 : performance.memory) === null || _a === void 0 ? void 0 : _a.usedJSHeapSize) || 0) / 1024 / 1024,
                    // @ts-ignore
                    total: (((_b = performance === null || performance === void 0 ? void 0 : performance.memory) === null || _b === void 0 ? void 0 : _b.totalJSHeapSize) || 0) / 1024 / 1024,
                    // @ts-ignore
                    limit: (((_c = performance === null || performance === void 0 ? void 0 : performance.memory) === null || _c === void 0 ? void 0 : _c.jsHeapSizeLimit) || 0) / 1024 / 1024,
                },
            };
            window.perf.events.push(event);
            if (window.perf.table) {
                const row = {};
                if (window.perf.name) {
                    row.type = type;
                    row.name = event.name;
                }
                if (window.perf.time) {
                    row.delta = `+${event.delta.toFixed(0)}ms`;
                }
                if (window.perf.heap) {
                    row.heap = `${event.heap.used.toFixed(0)}/${event.heap.total.toFixed(0)}/${event.heap.limit.toFixed(0)}MB`;
                }
                if (window.perf.data && event.data) {
                    row.data = event.data;
                }
                console.table(row);
            }
            else {
                const parts = [];
                if (window.perf.name)
                    parts.push(`${type} | ${event.name}`);
                if (window.perf.time)
                    parts.push(`+${event.delta.toFixed(0)}ms`);
                if (window.perf.heap)
                    parts.push(`${event.heap.used.toFixed(0)}/${event.heap.total.toFixed(0)}/${event.heap.limit.toFixed(0)}MB`);
                if (window.perf.data && event.data)
                    parts.push(JSON.stringify(event.data));
                const color = type === 'Success'
                    ? 'color: green'
                    : type === 'Error'
                        ? 'color: red'
                        : type === 'Debug'
                            ? 'color: gray'
                            : 'color: inherit';
                console.log(`%c✓ ${parts.join(' | ')}`, color);
            }
        },
        // Maintain existing interface
        Debug: (name, data = null) => window.perf._addEvent(name, 'Debug', data),
        Info: (name, data = null) => window.perf._addEvent(name, 'Info', data),
        Success: (name, data = null) => window.perf._addEvent(name, 'Success', data),
        Error: (name, data = null) => window.perf._addEvent(name, 'Error', data),
        setLevel(level, enabled) {
            this._levelEnabled[level] = enabled;
            sessionStorage.setItem(`perf_level_${level.toLowerCase()}`, enabled);
        },
        get help() {
            console.log('%cPerformance Logger Help:', 'font-weight: bold; font-size: 14px;');
            console.log('\n%cDisplay Settings:', 'font-weight: bold');
            console.table({
                table: { description: 'Toggle between table/inline view', current: this.table },
                time: { description: 'Show timing information', current: this.time },
                heap: { description: 'Show memory usage', current: this.heap },
                name: { description: 'Show event names', current: this.name },
                data: { description: 'Show custom data', current: this.data },
            });
            console.log('\n%cLog Levels:', 'font-weight: bold');
            console.table({
                DEBUG: { level: 0, enabled: this.levelEnabled.DEBUG, color: 'gray' },
                INFO: { level: 1, enabled: this.levelEnabled.INFO, color: 'default' },
                SUCCESS: { level: 2, enabled: this.levelEnabled.SUCCESS, color: 'green' },
                ERROR: { level: 3, enabled: this.levelEnabled.ERROR, color: 'red' },
            });
            console.log('\n%cAvailable Commands:', 'font-weight: bold');
            console.table({
                'perf.on()': { description: 'Enable logging' },
                'perf.off()': { description: 'Disable logging' },
                'perf.reset()': { description: 'Reset to defaults' },
                'perf.level = perf.levels.INFO': { description: 'Set log level' },
                'perf.setLevel("DEBUG", false)': { description: 'Toggle specific log level' },
            });
            console.log('\n%cLogging Methods:', 'font-weight: bold');
            console.table({
                'Debug(name, data?)': { color: 'gray', example: 'perf.Debug("message", data)' },
                'Info(name, data?)': { color: 'default', example: 'perf.Info("message", data)' },
                'Success(name, data?)': { color: 'green', example: 'perf.Success("message", data)' },
                'Error(name, data?)': { color: 'red', example: 'perf.Error("message", data)' },
            });
            return 'Use these commands to control the performance logger';
        },
        // Initialize the _levelEnabled properties with getters/setters
        _initLevelEnabled() {
            ;
            ['DEBUG', 'INFO', 'SUCCESS', 'ERROR'].forEach((level) => {
                const stored = sessionStorage.getItem(`perf_level_${level.toLowerCase()}`);
                let currentValue = stored === null ? true : stored !== 'false';
                Object.defineProperty(this._levelEnabled, level, {
                    get: () => currentValue,
                    set: (newValue) => {
                        sessionStorage.setItem(`perf_level_${level.toLowerCase()}`, newValue);
                        currentValue = newValue;
                    },
                    enumerable: true,
                    configurable: true,
                });
            });
        },
        Assert(condition, message = '', data = null) {
            if (!condition) {
                console.error('Assertion failed:', message, data);
                debugger;
            }
        },
    };
    // Call initialization
    window.perf._initLevelEnabled();
}
