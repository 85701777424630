import { classRegistry, Textbox, IText, controlsUtils, Control, FabricText } from 'fabric';
// Throttling prevents us from resizing so quickly that we experience lag. I don't like it but it seems necessary for now
// with large amounts of text
export function throttle(func, limit = 100) {
    let inThrottle;
    return function (eventData, transform, x, y) {
        if (!inThrottle) {
            const result = func(eventData, transform, x, y);
            inThrottle = true;
            setTimeout(() => {
                inThrottle = false;
            }, limit);
            return result;
        }
        return false;
    };
}
const originOffset = {
    left: -0.5,
    top: -0.5,
    center: 0,
    bottom: 0.5,
    right: 0.5,
};
/**
 * Resolves origin value relative to center
 * @private
 * @returns number
 */
export const resolveOrigin = (originValue) => typeof originValue === 'string'
    ? originOffset[originValue]
    : originValue - 0.5;
/**
 * Checks if transform is centered
 * @param {Object} transform transform data
 * @return {Boolean} true if transform is centered
 */
export function isTransformCentered(transform) {
    return (resolveOrigin(transform.originX) === resolveOrigin('center') &&
        resolveOrigin(transform.originY) === resolveOrigin('center'));
}
/**
 * Action handler to change object's width
 * Needs to be wrapped with `wrapWithFixedAnchor` to be effective
 * @param {Event} eventData javascript event that is doing the transform
 * @param {Object} transform javascript object containing a series of information around the current transform
 * @param {number} x current mouse x position, canvas normalized
 * @param {number} y current mouse y position, canvas normalized
 * @return {Boolean} true if some change happened
 */
export const changeObjectHeight = (eventData, transform, x, y) => {
    const localPoint = controlsUtils.getLocalPoint(transform, transform.originX, transform.originY, x, y);
    //  make sure the control changes width ONLY from it's side of target
    if (resolveOrigin(transform.originY) === resolveOrigin('center') ||
        (resolveOrigin(transform.originY) === resolveOrigin('bottom') &&
            localPoint.y < 0) ||
        (resolveOrigin(transform.originY) === resolveOrigin('top') &&
            localPoint.y > 0)) {
        const { target } = transform, strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1), multiplier = isTransformCentered(transform) ? 2 : 1, oldHeight = target.height, newHeight = Math.abs((localPoint.y * multiplier) / target.scaleY) - strokePadding;
        target.set('height', Math.max(newHeight, 1));
        //  check against actual target width in case `newWidth` was rejected
        return oldHeight !== target.height;
    }
    return false;
};
export const changeObjectWidth = (eventData, transform, x, y) => {
    const localPoint = controlsUtils.getLocalPoint(transform, transform.originX, transform.originY, x, y);
    //  make sure the control changes width ONLY from it's side of target
    if (resolveOrigin(transform.originX) === resolveOrigin('center') ||
        (resolveOrigin(transform.originX) === resolveOrigin('right') &&
            localPoint.x < 0) ||
        (resolveOrigin(transform.originX) === resolveOrigin('left') &&
            localPoint.x > 0)) {
        const { target } = transform, strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1), multiplier = isTransformCentered(transform) ? 2 : 1, oldWidth = target.width, newWidth = Math.abs((localPoint.x * multiplier) / target.scaleX) - strokePadding;
        target.set('width', Math.max(newWidth, 1));
        const changed = oldWidth !== target.width;
        return changed;
    }
    return false;
};
export const changeObjectWidthAndHeight = (eventData, // not used
transform, x, y) => {
    const localPoint = controlsUtils.getLocalPoint(transform, transform.originX, transform.originY, x, y);
    let heightChanged = false;
    let widthChanged = false;
    //  make sure the control changes width ONLY from it's side of target
    if (resolveOrigin(transform.originY) === resolveOrigin('center') ||
        (resolveOrigin(transform.originY) === resolveOrigin('bottom') &&
            localPoint.y < 0) ||
        (resolveOrigin(transform.originY) === resolveOrigin('top') &&
            localPoint.y > 0)) {
        const { target } = transform, strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1), multiplier = isTransformCentered(transform) ? 2 : 1, oldHeight = target.height, newHeight = Math.abs((localPoint.y * multiplier) / target.scaleY) - strokePadding;
        target.set('height', Math.max(newHeight, 1));
        //  check against actual target width in case `newWidth` was rejected
        heightChanged = oldHeight !== target.height;
    }
    if (resolveOrigin(transform.originX) === resolveOrigin('center') ||
        (resolveOrigin(transform.originX) === resolveOrigin('right') &&
            localPoint.x < 0) ||
        (resolveOrigin(transform.originX) === resolveOrigin('left') &&
            localPoint.x > 0)) {
        const { target } = transform, strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1), multiplier = isTransformCentered(transform) ? 2 : 1, oldWidth = target.width, newWidth = Math.abs((localPoint.x * multiplier) / target.scaleX) - strokePadding;
        target.set('width', Math.max(newWidth, 1));
        //  check against actual target width in case `newWidth` was rejected
        widthChanged = oldWidth !== target.width;
    }
    return heightChanged || widthChanged;
};
export const changeHeight = controlsUtils.wrapWithFireEvent('resizing', controlsUtils.wrapWithFixedAnchor(changeObjectHeight));
const changeWidth = controlsUtils.wrapWithFireEvent('resizing', controlsUtils.wrapWithFixedAnchor(changeObjectWidth));
const changeAtCorner = controlsUtils.wrapWithFireEvent('resizing', controlsUtils.wrapWithFixedAnchor(changeObjectWidthAndHeight));
export const createResizeControls = () => ({
    mt: new Control({
        x: 0,
        y: -0.5,
        actionHandler: throttle(changeHeight, 10),
        cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
        actionName: 'resizing',
        mouseDownHandler: (eventData, transform, x, y) => {
            // @ts-ignore
            transform.target.changingHeight = true;
        },
        mouseUpHandler: (eventData, transform, x, y) => {
            // we can signal that we're done changing the height
            // @ts-ignore
            transform.target.changingHeight = false;
        }
    }),
    mb: new Control({
        x: 0,
        y: 0.5,
        actionHandler: throttle(changeHeight, 10),
        cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
        actionName: 'resizing',
        mouseDownHandler: (eventData, transform, x, y) => {
            // @ts-ignore
            transform.target.changingHeight = true;
        },
        mouseUpHandler: (eventData, transform, x, y) => {
            // we can signal that we're done changing the height
            // @ts-ignore
            transform.target.changingHeight = false;
        }
    }),
    br: new Control({
        x: 0.5,
        y: 0.5,
        cursorStyleHandler: controlsUtils.scaleCursorStyleHandler,
        actionHandler: throttle(changeAtCorner, 10),
        mouseUpHandler: (eventData, transform, x, y) => {
            // we can signal that we're done changing the height
            // @ts-ignore
            transform.target.changingHeight = false;
            // we can signal that we're done changing the width
            // @ts-ignore
            transform.target.changingWidth = false;
        }
    }),
    bl: new Control({
        x: -0.5,
        y: 0.5,
        cursorStyleHandler: controlsUtils.scaleCursorStyleHandler,
        actionHandler: throttle(changeAtCorner, 10),
        mouseUpHandler: (eventData, transform, x, y) => {
            // we can signal that we're done changing the height
            // @ts-ignore
            transform.target.changingHeight = false;
            // we can signal that we're done changing the width
            // @ts-ignore
            transform.target.changingWidth = false;
        }
    }),
    tl: new Control({
        x: -0.5,
        y: -0.5,
        cursorStyleHandler: controlsUtils.scaleCursorStyleHandler,
        actionHandler: throttle(changeAtCorner, 10),
        mouseUpHandler: (eventData, transform, x, y) => {
            // we can signal that we're done changing the height
            // @ts-ignore
            transform.target.changingHeight = false;
            // we can signal that we're done changing the width
            // @ts-ignore
            transform.target.changingWidth = false;
        }
    }),
    tr: new Control({
        x: 0.5,
        y: -0.5,
        cursorStyleHandler: controlsUtils.scaleCursorStyleHandler,
        actionHandler: throttle(changeAtCorner, 10),
        mouseUpHandler: (eventData, transform, x, y) => {
            // we can signal that we're done changing the height
            // @ts-ignore
            transform.target.changingHeight = false;
            // we can signal that we're done changing the width
            // @ts-ignore
            transform.target.changingWidth = false;
        }
    }),
});
export const createLRResizeControls = () => ({
    mr: new Control({
        x: 0.5,
        y: 0,
        actionHandler: throttle(changeWidth, 10),
        cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
        actionName: 'resizing',
        mouseDownHandler: (eventData, transform, x, y) => {
            // @ts-ignore
            transform.target.changingWidth = true;
            // @ts-ignore
            transform.target.minHeight = transform.target.height;
        },
        mouseUpHandler: (eventData, transform, x, y) => {
            // we can signal that we're done changing the width
            // @ts-ignore
            transform.target.changingWidth = false;
        }
    }),
    ml: new Control({
        x: -0.5,
        y: 0,
        actionHandler: throttle(changeWidth, 10),
        cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
        actionName: 'resizing',
        mouseDownHandler: (eventData, transform, x, y) => {
            // @ts-ignore
            transform.target.changingWidth = true;
            // @ts-ignore
            transform.target.minHeight = transform.target.height;
        },
        mouseUpHandler: (eventData, transform, x, y) => {
            // we can signal that we're done changing the width
            // @ts-ignore
            transform.target.changingWidth = false;
        }
    }),
});
class CustomTextbox extends Textbox {
    constructor(text, options) {
        super(text, options);
        this.changingWidth = false;
        this.changingHeight = false;
        this.minHeight = 0;
        // Large text can get kinda blurry when the size of height/width are large, and this prevents that :  ^)
        this.objectCaching = false;
        this.changingWidth = false;
        this.changingHeight = false;
        this.bordered = (options === null || options === void 0 ? void 0 : options.bordered) || false;
        this.textboxBorderColor = (options === null || options === void 0 ? void 0 : options.borderColor) || 'black';
        this.textboxBorderWidth = (options === null || options === void 0 ? void 0 : options.borderWidth) || 2;
        // Add vertical alignment property with default value
        this.verticalTextAlign = (options === null || options === void 0 ? void 0 : options.verticalTextAlign) || 'top'; // can be 'top', 'center', or 'bottom'
    }
    // mostly copy/pasted from Textbox but height changes were added
    initDimensions() {
        if (!this.initialized) {
            return;
        }
        this.isEditing && this.initDelayedCursor();
        this._clearCache();
        // clear dynamicMinWidth as it will be different after we re-wrap line
        this.dynamicMinWidth = 0;
        // wrap lines
        this._styleMap = this._generateStyleMap(this._splitText());
        // if after wrapping, the width is smaller than dynamicMinWidth, change the width and re-wrap
        if (this.dynamicMinWidth > this.width) {
            this._set('width', this.dynamicMinWidth);
        }
        if (this.textAlign.includes('justify')) {
            // once text is measured we need to make space fatter to make justified text.
            this.enlargeSpaces();
        }
        // BIG NOTE:
        //   Okay so it's kinda hard to know how to adjust height for the best UX. This logic makes the height extend
        //   to be as tall as necessary to contain all the text when we're shifting the width, but also to make it as tall
        //   as you want it to be by just dragging the control. But when you shift the width to the narrowest it extends
        //   the height a _lot_ and then the user, if they let go of the width changing, will have to drag the height controls
        //   back to a reasonable height. I don't think that's _wrong_ but I also think it may be a worse UX than keeping the
        //   height static and letting the text exceed the bounding box if you narrow the width too much relative to the height.
        //   So that's why this code is commented out. It may be useful in the future and offering different text fit modes for 
        //   the textbox may be the best approach for the future so I'm leaving this code for that potential future. 
        //   This is basically how Google Slides works. 
        //   
        // // We need to properly set the height but we must make sure that it's as large as it needs to be 
        // // for all the text to fit and display properly.
        this.dynamicMinHeight = this.height;
        this.calculatedTextHeight = this.calcTextHeight();
        // this.height = this.calculatedTextHeight
        this.tentativeHeight = this.height;
        if (this.dynamicMinHeight > this.calculatedTextHeight) {
            this.tentativeHeight = this.dynamicMinHeight;
            this._set('height', this.dynamicMinHeight);
        }
        else {
            this.tentativeHeight = this.calculatedTextHeight;
            this._set('height', this.calculatedTextHeight);
        }
        // ================================
        if (this.changingWidth && !this.changingHeight) {
            if (this.tentativeHeight > this.minHeight) {
                this._set('height', Math.max(this.minHeight, this.calculatedTextHeight));
            }
        }
        this.setCoords();
    }
    /**
     * @param {CanvasRenderingContext2D} ctx Context to render on
     */
    render(ctx) {
        super.render(ctx);
        // clear the cursorOffsetCache, so we ensure to calculate once per renderCursor
        // the correct position but not at every cursor animation.
        if (this.bordered) {
            ctx.save();
            ctx.strokeStyle = this.textboxBorderColor;
            ctx.lineWidth = this.textboxBorderWidth;
            // Translate to the object
            ctx.translate(this.left, this.top);
            // math comes from the rotate documentation :)
            ctx.rotate(this.angle * Math.PI / 180);
            ctx.strokeRect(-1, // x position
            -1, // y position
            this.width + 3, // width
            this.height + 3 // height
            );
            ctx.restore();
        }
    }
    /**
     * @return {Number} Top offset
     */
    _getTopOffset() {
        // NOTE: We override _getTopOffset so we account for the height of all the lines and shift them down if we are
        //   vertically centering the text...
        if (this.verticalTextAlign === 'top') {
            return -this.height / 2;
        }
        let totalHeightOfAllLines = this.calcTextHeight();
        if (this.verticalTextAlign === 'center') {
            let verticalCenterMargin = (this.height - totalHeightOfAllLines) / 2;
            return (-this.height / 2) + verticalCenterMargin;
        }
        else if (this.verticalTextAlign === 'bottom') {
            let verticalCenterMargin = (this.height - totalHeightOfAllLines);
            return (-this.height / 2) + verticalCenterMargin;
        }
        // whoops how did this happen...? :)
    }
    // OVERRIDE THIS TO PREVENT SCALING ON THE Y
    // this will be found int he commonControls.ts file in the fabric library
    // We do it for the X, we can do it for the Y..... probably. Thenw e'll need to adjust the height
    static createControls() {
        // Use the default Textbox controls as a base
        return {
            controls: Object.assign(Object.assign(Object.assign({}, controlsUtils.createObjectDefaultControls()), createResizeControls()), createLRResizeControls())
        };
    }
    /**
     * Returns object representation of an instance
     * @method toObject
     * @param {Array} [propertiesToInclude] Any properties that you might want to additionally include in the output
     * @return {Object} object representation of an instance
     */
    toObject(propertiesToInclude = []) {
        return super.toObject([
            'verticalTextAlign',
            ...propertiesToInclude
        ]);
    }
}
CustomTextbox.type = 'CustomTextbox';
// We call .set(...) in the control handlers we made, for 'changeWidth' and 'changeHeight'. That will call .set() on the Text.ts
// and that will check the textLayoutProperties to see if "needsDim" which it will with 'height' and 'width' here 
// which means '.initDimensions' is continuously called
CustomTextbox.textLayoutProperties = [...IText.textLayoutProperties, 'width', 'height'];
// Register the class
classRegistry.setClass(CustomTextbox, 'CustomTextbox');
classRegistry.setSVGClass(CustomTextbox);
export default CustomTextbox;
